export default function scrollGsapImg() {

	gsap.registerPlugin(ScrollTrigger)
	const cardsWrapper = document.querySelector('#home-nossos-clientes .cards-wrapper')
	if (!cardsWrapper) return
	const col1 = cardsWrapper.querySelector('.col-1'),
		col2 = cardsWrapper.querySelector('.col-2')

	const value = 300,
		duration = 40

	gsap.set(
		col1,
		{
			y: -value,
		},
		'juntas'
	)
	gsap.set(
		col2,
		{
			y: value,
		},
		'juntas'
	)


	const tl = gsap
		.timeline({ paused: true, delay: 0, ease: 'linear', repeat: -1, yoyo: true, yoyoEase: false })
		.to(
			col1,
			{
				duration: duration,
				y: value,
			},
			'juntas'
		)
		.to(
			col2,
			{
				duration: duration,
				y: -value,
			},
			'juntas'
		)

	window.tl = tl

	ScrollTrigger.create({
		trigger: cardsWrapper,
		once: false,
		start: 'top-=100px',
		refreshPriority: 10,
		//markers: true,
		onEnter: function () {
			tl.play()
		},
	})
}
