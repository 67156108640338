export default function changeItemHistoria() {
	const itens = document.querySelectorAll('[data-item-history]')

	itens.forEach((i) => {
		i.addEventListener('click', () => {
			itens.forEach((i) => {
				const itemActive = i.querySelector('a')
				const itemDecorator = i.querySelector('div')
				itemActive.classList.add('text-neutral-60')
				itemActive.classList.remove('text-blue-footer')
				itemDecorator.classList.add('hidden')
			})

			const itemActive = i.querySelector('a')
			const itemDecorator = i.querySelector('div')
			itemActive.classList.remove('text-neutral-60')
			itemActive.classList.add('text-blue-footer')
			itemDecorator.classList.remove('hidden')
		})
	})
}
