import axios from 'axios'
import axiosConfig from './modules/axiosConfig'
import PageLogin from './pages/Login'
import UseMouseOrKeyboard from './modules/UseMouseOrKeyboard'
import DesignSystem from './DesignSystem/DesignSystem'
import { register } from 'swiper/element/bundle'
import logs from './utils/logs'
import ripple from './modules/ripple'
import formErros from './modules/formErros'
import animaAoScroll from './animation/animaAoScroll'
import animateFrom from './animation/animateFrom'
import animateSetup from './animation/animateSetup'
import homeNegocios from './pages/home_negocios'
import scrollGsapImg from './pages/scrollGsapImg'
import contatoChange from './pages/contatoChange'
import animationNumbers from './pages/counter.js'
import nossaHistoria from './pages/nossaHistoria.js'
import contato from './pages/Contato'
import trabalheConosco from './pages/trabalheConosco'
import duvidasSugestoes from './pages/duvidasSugestoes'
import denuncias from './pages/denuncias'
// import SvgUse from "./utils/SvgUse";
//Pages

const pageNossosClientes = document.querySelector('.body-nossos-clientes')
const pageContato = document.querySelector('.body-contato')
const pageNossaHistoria = document.querySelector('.body-nossa-historia')
const pageCompliance = document.querySelector('.body-integridade-complianc')

// Init
GLOBAL.DesignSystem = DesignSystem({})
register() // Swiper Web Component

// Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios)
UseMouseOrKeyboard()
ripple()
formErros()
animateSetup()
animaAoScroll()
//animateFrom()
animationNumbers()

if (pageNossosClientes) {
	scrollGsapImg()
} else if (pageContato) {
	contatoChange()
	contato()
	trabalheConosco()
	duvidasSugestoes()
} else if (pageNossaHistoria) {
	nossaHistoria()
}else if (pageCompliance) {
	denuncias()
}

// Pages
homeNegocios()

const menuItems = document.querySelectorAll('div[js-menu-item]')

if (window.matchMedia('(max-width:1024px)').matches && menuItems) {
	menuItems.forEach((menuLink) => {
		menuLink.addEventListener('click', (e) => {
			e.preventDefault()
			const isAccordion = e.target.closest('div[js-accordion-item]')
			if (!isAccordion) {
				// If it's not an accordion, navigate to the link URL
				const linkElement = e.currentTarget.querySelector('a')
				if (linkElement) {
					window.location.href = linkElement.href
				}
			} else {
				// const accordionContent = isAccordion.querySelector('ul[js-accordion-content]')
				// // Create a new list item
				// const newItem = document.createElement('li');
				// newItem.className = 'text-paragraph-1 text-primary-light first:mt-32';
				// newItem.innerHTML = `
				// 	<a href="/grupo_family_office">
				// 		AAAAAAAAA
				// 	</a>
				// `;
				// // Append the new item to the accordion content
				// accordionContent.appendChild(newItem);
			}
		})
	})
}

// Find all accordion items
const accordionItems = document.querySelectorAll('dialog[js-modal="modalMenu"] [js-accordion-item]')

// Iterate through each accordion item
if (accordionItems) {
	accordionItems.forEach((accordionItem, index) => {
		const firstLink = accordionItem.querySelector('div a')

		if (firstLink) {
			// Create a new list item
			const newItem = document.createElement('li')
			newItem.className = 'text-paragraph-1 text-white first:mt-32'
			newItem.innerHTML = `
                <a href="${firstLink.getAttribute('href')}">
                    ${firstLink.textContent}
                </a>
            `

			// Append the new item to the accordion content
			accordionItem.querySelector('ul').appendChild(newItem)
		} else {
			console.error(`No first link found for accordion item ${index + 1}`)
		}
	})
}

PageLogin()?.init()

// utils logs
logs()
